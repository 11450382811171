.verification{
    .ReactInputVerificationCode__item.is-active:after {
        background-color: #9B9B9B66;
      }
     
     .ReactInputVerificationCode__item {
        position: relative;
width: 60px;
height: 60px;
        font-weight: 500;
        color: #2D2D2D !important;
      }
      .ReactInputVerificationCode__item:after {
        content: "";
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        height: 2px;
        background-color: #ebebeb;
        transition: background-color 0.2s ease-out;
      }
      .custom-styles .ReactInputVerificationCode__item,
.custom-styles .ReactInputVerificationCode__item.is-active {
  box-shadow: none;
  color: #2D2D2D;

}
.css-ae0g2e{
  width: 287px;
}
    
.loading-spiner-center{
  position: relative;
  top: 49px;
}

}