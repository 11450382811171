.area-review{
    margin-top: 30px;
            background: #FFFFFF 0% 0% no-repeat padding-box;
            box-shadow: 0px 26px 64px #00000008;
            border-radius: 10px;
        /*     width: 339px; */
         /*    height: 97px; */
 /*     padding:5px 5px 5px 17px; */
 padding: 15px;
     width: 100%;
     border-color: #FFFFFF;
    /*     height: 97px; */
   



            } textarea:focus  { 
                border-color: #eaeaeaad;

                outline: none;
            }