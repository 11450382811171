.item-change {
    border-bottom: 1.2px solid #e0e0e0;
    padding-top: 2px;
    padding-bottom: 2px;
    ion-label {
        h1 {
            font: normal normal 600 16px/20px Milliard Book, Tajawal;
            letter-spacing: 0px;
            color: #2d2d2f !important;
        }
        p {
            font: normal normal normal 12px/18px Milliard Book, Tajawal;
            letter-spacing: 0px;
            color: #9b9b9b !important;
        }
    }
    .change-text {
        font: normal normal bold 14px/17px Milliard Book, Tajawal;
        letter-spacing: -0.1px;
        color: #ee417f;
    }
}
