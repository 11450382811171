.item-search {
    border-bottom: 1.2px solid #e0e0e0;
    .thumbnail {
        width: 70px;
        height: 70px;
        .img {
            border: 0.8462259769439697px solid #ffffff;
            border-radius: 10px;
            width: 70px;
            height: 70px;
            pointer-events: none;
        }
    }
    ion-label {
        h1 {
            font: normal normal 600 17px/20px Milliard Book, Tajawal;
            letter-spacing: 0px;
            color: #2d2d2f !important;
        }
        .category {
            padding-top: 3px;
            font: normal normal 600 15px/18px Milliard Book, Tajawal;
            letter-spacing: 0px;
            color: #ee417f !important;
            opacity: 1;
        }
        .price {
            padding-top: 3px;
        }
    }
    .discount-area {
        border-radius: 10px 0px;
        opacity: 1;
        width: 75px;
        height: 25px;
        background: #ee417f;
        text-align: center;
        .text {
            font: normal normal 600 13px/20px Milliard Book, Tajawal;
            letter-spacing: 0px;
            color: #f6f6f6;
        }
    }
}
