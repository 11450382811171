.all-product {
    .card-item {
        text-align: initial;
        color: unset;
        width: 100%;
        max-width: 200px;
        height: 100%;
        text-decoration: none;
        box-shadow: none;
        margin: 0;
        margin-top: 10px;
        background: #ff000000;
        .discount-badge {
            position: absolute;
            top: 135px;
            right: 0;
            text-align: center;
            background: #ee417f;
            border-radius: 10px 0px;
            color: #f6f6f6;
            width: 75px;
            height: 25px;
            font: normal normal 600 13px/20px Milliard Book, Tajawal;
        }
        .discount-badge-ar {
            position: absolute;
            top: 135px;
            left: 0;
            text-align: center;
            background: #ee417f;
            border-radius: 0px 10px;
            color: #f6f6f6;
            width: 75px;
            height: 25px;
            font: normal normal 600 13px/20px Milliard Book, Tajawal;
        }
        .img-grid {
            width: 100%;
            max-width: 200px;

            height: 160px;
            border-radius: 10px;
            object-fit: cover;
        }
        .item {
            --padding-start: 0;
            ion-label {
                margin-top: 8px;
                .title {
                    color: #2d2d2f;
                    font: normal normal 600 16px/20px Milliard Book, Tajawal;
                }
                .category {
                    font: normal normal normal 14px/18px Milliard Book, Tajawal;
                    letter-spacing: 0px;
                    color: #2967ff;
                }
                .price-base {
                    margin-top: 5px;
                    .base {
                        font: normal normal normal 16px/20px Milliard Book,
                            Tajawal;
                        letter-spacing: 0px;
                        color: #2d2d2f;
                    }
                    .discount {
                        padding-left: 15px;
                        font: normal normal normal 14px/18px Milliard Book,
                            Tajawal;
                        letter-spacing: 0px;
                        color: #8d8d8e;
                    }
                }
            }
        }
    }
}
