#slide {
    margin-left: 8px;
    margin-bottom: 1px;
    .swiper-container {
        width: 100%;
        height: 100%;
    }

    .swiper-slide {
        text-align: center;
        /*     margin: 0 -4px; */
        /* flex:0.8 0.8  auto;
 */

        display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        -webkit-justify-content: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        -webkit-align-items: center;
        align-items: center;
    }
    .sli {
        text-align: -webkit-center;
    }
    .text {
        width: 65px;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        font: normal normal 500 14px/18px Milliard Book, Tajawal;
    }
    .circal {
        width: 54px;
        padding: 2px;
        background: linear-gradient(
                135deg,
                rgb(238, 65, 127) 0%,
                rgb(102, 128, 198) 100%
            )
            0% 0% no-repeat padding-box padding-box transparent;
        border-radius: 50%;
        .circal-card {
            box-shadow: unset;
            background: 0% 0% no-repeat padding-box padding-box
                rgb(255, 255, 255);
            border-radius: 50%;
            width: 48px;
            height: 48px;
            margin: 1px;
            .icon-slide {
                width: 32px;
                height: 27px;
                position: relative;
                top: 10.5px;
                /*     left: 11px; */
                font-size: 25px;
            }
        }
    }
}
