.header-profile-info {
    padding-top: 60px;
    text-align: -webkit-center;
    .thumbanail-profile {
        /*      border: 2px solid #FFFFFF; */
        /* border-radius: 10px; */
        width: 80px;
        height: 80px;
        .filter-img {
            background: transparent
                linear-gradient(100deg, #ee417f 0%, #6680c6 100%) 0% 0%
                no-repeat padding-box;
            border-radius: 10px;
            opacity: 0.46;
            filter: blur(6px);
            width: 62px;
            height: 11px;
            position: relative;
            /* top: 5px; */
            bottom: 15px;
        }
        img {
            border: 2px solid #ffffff;
            border-radius: 10px;
        }
    }
    .name-content {
        padding-top: 8px;

        .name {
            font: normal normal 600 18px/20px Milliard Book, Tajawal;
            letter-spacing: 0px;
            color: #2d2d2f;
        }
    }
    .email {
        font: normal normal normal 15px/28px Milliard Book;
        letter-spacing: 0px;
        color: #2d2d2d;
        opacity: 1;
    }
}
