.labal-header-product {
    .header {
        font: normal normal 600 16px/20px Milliard Book, Tajawal;
        letter-spacing: 0px;
        color: #2d2d2f;
        opacity: 1;
    }

    .price {
        font: normal normal 600 16px/20px Milliard Book, Tajawal;
        letter-spacing: 0px;
        color: #2d2d2f;
    }
    .discount {
        font: normal normal normal 14px/18px Milliard Book, Tajawal;
        letter-spacing: 0px;
        color: #8d8d8e;
        padding-right: 15px;
        padding-left: 15px;
    }
    .margin-top {
        margin-top: 5px;
    }
    .category {
        text-align: left;
        font: normal normal normal 14px/18px Milliard Book, Tajawal;
        letter-spacing: 0px;
        color: #ee417f;
        float: right;
    }
    .category-ar {
        text-align: left;
        font: normal normal normal 14px/18px Milliard Book, Tajawal;
        letter-spacing: 0px;
        color: #ee417f;
        float: left;
    }
}
