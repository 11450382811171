.size-chip {
    border: 1px solid #6680c6;
    border-radius: 5px;
    font: normal normal normal 14px/18px Milliard Book, Tajawal;
    letter-spacing: 0px;
    color: #6680c6;

    &.active {
        background: #6680c6;
        font: normal normal normal 14px/18px Milliard Book, Tajawal;
        letter-spacing: 0px;
        color: #f9f9f9;
    }
}
