.item-sarech {
    margin-top: 30px;
    margin-bottom: 30px;
    /* padding-top: 10px;
padding-bottom: 10px; */
    .icon-search {
        color: #9b9b9b;
        margin-right: 10px;
        margin-bottom: 10px;
    }
    .input-search {
        outline: none;
        font: normal normal normal 15px/16px Milliard Book, Tajawal;
        margin-bottom: 10px;
        width: 100%;
        height: 30px;
        background: unset;
        border: unset;
    }
    /*     border-bottom: 1px solid rgb(20, 20, 20); */
}
