.loading-spiner {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -50px;
  margin-left: -24px;
  .spiner {
    width: 50px;
    height: 50px;
  }
}
