.button-kit {
    &.fill {
        background: var(--color-gradient);
        border-radius: 10px;
        color: #f9f9f9;
        --box-shadow: '0';
        font: normal normal 600 16px/19px Milliard ExtraLight, Tajawal;
    }
    &.clear {
        border: 1px solid #6680c6;
        border-radius: 10px;
        color: #6e7cc1;
        --box-shadow: '0';
        text-transform: uppercase;
        font: normal normal 600 16px/19px Milliard ExtraLight, Tajawal;
    }
}
