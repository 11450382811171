#login{
    ion-footer{
    ion-toolbar{
        padding-bottom: 10px;
    }
    }
    .footer-ios ion-toolbar:first-of-type{
        --border-width:0 !important;
    }
    .footer-md::before{
    background-image:none !important;

}
    
}