.wishlist {
    .title-padding {
        padding-top: 10px;
        .title {
            font: normal normal 600 14px/18px Milliard Book, Tajawal;
            letter-spacing: 0px;
            color: #5e5e5e;
        }
    }
}
