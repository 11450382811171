.tabs-demo {
  ion-tab-bar {
    box-shadow: 0px -5px 18px #00000008;
    border-top-color: #f9f9f9;
  }

  .tab-selected {
    --color-selected: #ee417f;
    --ripple-color: #ee417f;
    --color-focused: #6680c6;
    /*         color:#EE417F; */
    /*        background: transparent linear-gradient(128deg, #EE417F 0%, #6680C6 100%) 0% 0% ;; */
    background-image: transparent
      linear-gradient(128deg, #ee417f 0%, #6680c6 100%) 0% 0% !important;
  }
}
