.item-chips {
    background: #eaeaeb 0% 0% no-repeat padding-box;
    border-radius: 6px;
    /* width: 66px; */
    height: 32px;
    font: normal normal normal 12px/20px Milliard Book, Tajawal;
    letter-spacing: 0px;
    color: #2d2d2f;
    &.--selected {
        background: transparent
            linear-gradient(107deg, #ee417f 0%, #6680c6 100%) 0% 0% no-repeat
            padding-box;

        color: #f9f9f9;
    }
}
