.item-order-info {
    border-bottom: 1.4px solid #e0e0e0;
    margin-top: 10px;

    .thumbnail {
        width: 80px;
        height: 80px;
        .col-padding {
            padding: 0;
        }
        .one-image {
            border-radius: 10px;
            width: 100%;
            height: 100%;
            border: 0.8462259769439697px solid #ffffff;
        }

        .one-helf {
            border-radius: 0px 0px 10px 10px;
            border: 0.8462259769439697px solid #ffffff;
            width: 100%;
            height: 40px;
        }
        .two-image {
            border: 0.8462259769439697px solid #ffffff;
            width: 40px;
            height: 40px;
        }
        .three-image {
            border: 0.8462259769439697px solid #ffffff;
            width: 100%;
            height: 40px;
        }
    }

    .products-name {
        font: normal normal 600 16px/20px Milliard Book, Tajawal;
        letter-spacing: 0px;
        color: #2d2d2f !important;
        opacity: 1;
    }
    .price {
        font: normal normal 600 16px/20px Milliard Book, Tajawal;
        letter-spacing: 0px;
        color: #2d2d2f !important;
        padding-top: 5px;
        opacity: 1;
    }
    .chip-cancel-order {
        background: #ee417f1a 0% 0% no-repeat padding-box;
        border: 0.8462259769439697px solid #ee417f;
        border-radius: 10px;
        font: normal normal 600 11px/18px Milliard Book, Tajawal;
        letter-spacing: 0px;
        color: #eb4381;
        text-transform: capitalize;
        opacity: 1;
        height: 28px;
        margin-left: 0;
    }
    .text-state-order {
        font: normal normal 600 11px/18px Milliard Book, Tajawal;
        text-transform: capitalize;
    }
}
