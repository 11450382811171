/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

/** Ionic CSS Variables **/
@import url('/src/fonts//style.css');

:root {
    --color-gradient: transparent
        linear-gradient(104deg, #ee417f 0%, #6680c6 100%) 0% 0% no-repeat
        padding-box;
    /** primary **/
    --ion-color-pink: #ea4381;
    --ion-color-pink-rgb: 234, 67, 129;
    --ion-color-pink-contrast: #ffffff;
    --ion-color-pink-contrast-rgb: 255, 255, 255;
    --ion-color-pink-shade: #ce3b72;
    --ion-color-pink-tint: #ec568e;
    --ion-color-lightness: #6680c6;
    --ion-color-lightness-rgb: 102, 128, 198;
    --ion-color-lightness-contrast: #ffffff;
    --ion-color-lightness-contrast-rgb: 255, 255, 255;
    --ion-color-lightness-shade: #5a71ae;
    --ion-color-lightness-tint: #758dcc;

    --ion-color-primary: #3880ff;
    --ion-color-primary-rgb: 56, 128, 255;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255, 255, 255;
    --ion-color-primary-shade: #3171e0;
    --ion-color-primary-tint: #4c8dff;

    /** secondary **/
    --ion-color-secondary: #3dc2ff;
    --ion-color-secondary-rgb: 61, 194, 255;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 255, 255, 255;
    --ion-color-secondary-shade: #36abe0;
    --ion-color-secondary-tint: #50c8ff;

    /** tertiary **/
    --ion-color-tertiary: #5260ff;
    --ion-color-tertiary-rgb: 82, 96, 255;
    --ion-color-tertiary-contrast: #ffffff;
    --ion-color-tertiary-contrast-rgb: 255, 255, 255;
    --ion-color-tertiary-shade: #4854e0;
    --ion-color-tertiary-tint: #6370ff;

    /** success **/
    --ion-color-success: #2dd36f;
    --ion-color-success-rgb: 45, 211, 111;
    --ion-color-success-contrast: #ffffff;
    --ion-color-success-contrast-rgb: 255, 255, 255;
    --ion-color-success-shade: #28ba62;
    --ion-color-success-tint: #42d77d;

    /** warning **/
    --ion-color-warning: #ffc409;
    --ion-color-warning-rgb: 255, 196, 9;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0, 0, 0;
    --ion-color-warning-shade: #e0ac08;
    --ion-color-warning-tint: #ffca22;

    /** danger **/
    --ion-color-danger: #eb445a;
    --ion-color-danger-rgb: 235, 68, 90;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255, 255, 255;
    --ion-color-danger-shade: #cf3c4f;
    --ion-color-danger-tint: #ed576b;

    /** dark **/
    --ion-color-dark: #222428;
    --ion-color-dark-rgb: 34, 36, 40;
    --ion-color-dark-contrast: #ffffff;
    --ion-color-dark-contrast-rgb: 255, 255, 255;
    --ion-color-dark-shade: #1e2023;
    --ion-color-dark-tint: #383a3e;

    /** medium **/
    --ion-color-medium: #92949c;
    --ion-color-medium-rgb: 146, 148, 156;
    --ion-color-medium-contrast: #ffffff;
    --ion-color-medium-contrast-rgb: 255, 255, 255;
    --ion-color-medium-shade: #808289;
    --ion-color-medium-tint: #9d9fa6;

    /** light **/
    --ion-color-light: #f4f5f8;
    --ion-color-light-rgb: 244, 245, 248;
    --ion-color-light-contrast: #000000;
    --ion-color-light-contrast-rgb: 0, 0, 0;
    --ion-color-light-shade: #d7d8da;
    --ion-color-light-tint: #f5f6f9;
}
.ion-color-pink {
    --ion-color-base: var(--ion-color-pink);
    --ion-color-base-rgb: var(--ion-color-pink-rgb);
    --ion-color-contrast: var(--ion-color-pink-contrast);
    --ion-color-contrast-rgb: var(--ion-color-pink-contrast-rgb);
    --ion-color-shade: var(--ion-color-pink-shade);
    --ion-color-tint: var(--ion-color-pink-tint);
}
.ion-color-lightness {
    --ion-color-base: var(--ion-color-lightness);
    --ion-color-base-rgb: var(--ion-color-lightness-rgb);
    --ion-color-contrast: var(--ion-color-lightness-contrast);
    --ion-color-contrast-rgb: var(--ion-color-lightness-contrast-rgb);
    --ion-color-shade: var(--ion-color-lightness-shade);
    --ion-color-tint: var(--ion-color-lightness-tint);
}

.content-color {
    --background: #f9f9f9 0% 0% no-repeat padding-box;
}

.toastActive {
    transform: translateY(-70px) !important;
}
.activeButton {
    background: blue !important;
}
img {
    pointer-events: none;
}

.an-dialog-modal{
    --max-width: 70%;
}

.an-dialog-modal .modal-wrapper{
    min-height: 210px;
    height: auto;
}
