.item-comment {
    border-bottom: 1.2px solid #e0e0e0;
    .thumbnail {
        width: 50px;
        height: 50px;

        .img {
            border-radius: 10px;
            pointer-events: none;
        }
    }
    .name {
        font: normal normal 600 14px/20px Milliard Book, Tajawal;
        letter-spacing: 0px;
        color: #2d2d2f !important;
    }
    .time {
        float: right;
        font: normal normal normal 12px/16px Milliard Light, Tajawal;
        letter-spacing: 0px;
        color: #9b9b9b;
        opacity: 1;
    }
    .commtent-content {
        font: normal normal normal 13px/16px Milliard Light, Tajawal;
        letter-spacing: 0px;
        color: #9b9b9b !important;
        opacity: 1;
        white-space: initial;
    }
}
