.item-divider {
    margin-top: 10px;
    margin-bottom: 10px;
    height: 38px;
    background: #f1f1f1 0% 0% no-repeat padding-box;
    .title {
        font: normal normal bold 18px/22px Milliard bold, Tajawal;
        letter-spacing: 0px;
        color: #2d2d2f;
    }
}
