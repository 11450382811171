.header-sub {
    background: #f9f9f9 0% 0% no-repeat padding-box;
    &.header-md::after {
        background-image: unset !important;
    }
    ion-toolbar:last-of-type {
        --border-width: 0;
    }
    .title {
        font: normal normal bold 21px/32px Milliard Book, Tajawal;
        letter-spacing: 0px;
        color: #2d2d2d;
    }
    .back-buttom {
        position: relative;
        top: 2px;
    }
}
