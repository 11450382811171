.header-main {
    background: #f9f9f9 0% 0% no-repeat padding-box;
    &.header-md::after {
        background-image: unset !important;
    }
    ion-toolbar:last-of-type {
        --border-width: 0;
    }
    .title-page {
        font: normal normal bold 21px/32px Milliard SemiBold, Tajawal;
        letter-spacing: 0px;
        color: #2d2d2d !important;
        opacity: 1;
    }
    .sub-title-page {
        margin-top: 0;
        margin-bottom: 8px;
        font: normal normal 600 14px/18px Milliard Book, Tajawal;
        letter-spacing: 0px;
        color: #5e5e5e !important;
        opacity: 1;
    }
}
