.content-cancel-modal {
    text-align: center;
    .center-ju-screen {
        margin-top: 100px;

        .title {
            padding-top: 8px;
            .text {
                font: normal normal 600 14px/20px Milliard Book, Tajawal;
                letter-spacing: 0px;
                color: #2d2d2f;
            }
        }
        .subtitle {
            padding-top: 10px;
            .text {
                padding-top: 16px;
                font: normal normal normal 11px/18px Milliard Book, Tajawal;
                letter-spacing: 0px;
                color: #2d2d2f;
            }
        }
    }
}
