.item-radio {
    border-bottom: 1.2px solid #e0e0e0;
    background-color: #f9f9f9;
    ion-radio {
        --color-checked: transparent
            linear-gradient(135deg, #ee417f 0%, #6680c6 100%) 0% 0% no-repeat
            padding-box;
        --border-color-checked: red;
        /*   border: 1px solid #ffffff;
  border-radius: 50%; */
    }
    .title {
        font: normal normal 600 16px/20px Milliard Book, Tajawal;
        letter-spacing: 0px;
        color: #2d2d2f !important;
    }
    .descrption {
        padding-top: 5px;
        font: normal normal normal 12px/16px Milliard Book, Tajawal;
        letter-spacing: 0px;
        color: #9b9b9b !important;
    }
    .change {
        font: normal normal bold 14px/17px Milliard Book, Tajawal;
        letter-spacing: -0.14px;
    }
}
