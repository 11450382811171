.slider-image-post{
img{
    max-height: 430px;
width: 100%;
object-fit:cover ;


}
.swiper-slide, .swiper-pagination {
        
    text-align: center;
 /*    padding-left: 30px; 
     padding-right: 30px; */
   --bullet-background: #6680C659 ;
   --bullet-background-active:#F9F9F9;

.swiper-pagination-bullet{

    width: 13px;
    height: 4px;
    border-radius: 10px;
    opacity: 1;
}

.swiper-pagination-bullet-active {
    width: 21px;
    height: 4px;
    border-radius: 10px;
}}
}