
.ion-slides-start {
    height: 180px;
  
    .swiper-slide, .swiper-pagination {
        
        text-align: left;
        padding-left: 30px; 
         padding-right: 30px;
       --bullet-background: #6680C659 ;
       --bullet-background-active:#F9F9F9;

    .swiper-pagination-bullet{
  
        width: 11px;
        height: 5px;
        border-radius: 10px;
        opacity: 1;
    }
    
  .swiper-pagination-bullet-active {
        width: 19px;
        height: 5px;
        border-radius: 10px;
    }

     }
   
  }