.card-review {
    box-shadow: unset;
    border-bottom: 1px solid rgba(233, 232, 232, 0.733);
    border-radius: 0;
    .img-profile {
        border-radius: 10px;
    }
    .name {
        font: normal normal 600 14px/20px Milliard Book, Tajawal;
        letter-spacing: 0px;
        color: #2d2d2f !important;
    }
    .date-time {
        padding-top: 8px;
        font: normal normal normal 12px/16px Milliard Book, Tajawal;
        letter-spacing: 0px;
        color: #9b9b9b !important;
    }

    .base {
        width: 56px;
        height: 32px;
        border-radius: 10px;
        .content {
            position: relative;
            top: 5px;
            .icon-star {
                position: relative;
                top: 1px;
            }
        }
    }
    .content-card {
        padding-top: 0;
        padding-left: 87px;
        .text {
            font: normal normal normal 13px/16px Milliard Book, Tajawal;
            letter-spacing: 0px;
            color: #2d2d2d;
        }
    }
}
