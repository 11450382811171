.divider {
    background: #f9f9f9 0% 0% no-repeat padding-box;
    .title {
        font: normal normal 600 15px/18px Milliard Book, Tajawal;
    }
    .value {
        direction: ltr;
        font: normal normal bold 600 15px/18px Milliard Book, Tajawal;
    }
}
