.qty-selector {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #f6f6f600;
    border: 1px solid #b1bee0;
    border-radius: 10px;
    margin-top: 10px;
    height: 38px;
    width: 120px;
    ion-button {
        --color: #6680c6;
        font-size: 16px;
        margin-left: 8px;
        margin-right: 8px;
        font-weight: 500;
        .icon-quintity {
            &.right {
                position: relative;
                right: 10px;
                font-size: 25px;
            }

            &.left {
                position: relative;
                left: 10px;
                font-size: 25px;
            }
        }
    }

    ion-label {
        color: #2d2d2f !important;
        font: normal normal 600 15px/18px Milliard Book, Tajawal;
    }
}
