.item-vertical {
    .card {
        text-align: initial;
        color: unset;
        width: 100%;
        height: 100%;
        text-decoration: none;
        box-shadow: none;
        margin: 0;
        margin-top: 10px;
        background: #ff000000;
        .img-grid {
            width: 100%;
            height: 343px;
            box-shadow: 0px 4px 21px #7a7a7a40;

            border-radius: 10px;
            object-fit: cover;
        }

        .item-setting {
            position: absolute;
            top: 280px;
            width: 100%;
            padding-inline: 10px;
            .button-icon {
                color: #ffffff;
                margin: 0;
            }
        }
        .item {
            --padding-start: 0;
            --padding-end: 0px;
            padding-inline-end: 0;
            margin-top: 4px;
            ion-label {
                /* margin-top: 6px; */
                margin-right: 0;
                .title {
                    color: #2d2d2f;
                    font: normal normal 600 18px/20px Milliard Book, Tajawal;
                }
                .price {
                    font: normal normal 600 17px/20px Milliard Book;
                    letter-spacing: 0px;
                    color: #2d2d2f;
                }
                .category {
                    font: normal normal normal 15px/18px Milliard Book, Tajawal;
                    letter-spacing: 0px;
                    float: right;
                    color: #ee417f;
                }
                .discount {
                    font: normal normal normal 15px/18px Milliard Book, Tajawal;
                    letter-spacing: 0px;
                    color: #8d8d8e;
                    padding-left: 15px;
                }
            }
        }
    }
}
