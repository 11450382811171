#get-start {
    .backgeound--page {
        width: 100%;
        height: 100%;
        background-size: cover;

        .content {
            width: 100%;
            position: absolute;
            bottom: 50px;

            .img-content {
                /*     padding-right: 30px;
    padding-left: 30px;
    position: absolute;
    bottom: 100px; */
                position: relative;

                top: 40px;

                padding-left: 30px;
                padding-right: 30px;
                img {
                    width: 80px;
                }
            }
        }
    }
}
