/* #### Generated By: http://www.cufonfonts.com #### */

/* arabic */
* {
    font-family: Tajawal;
}
@font-face {
    font-family: 'Tajawal';
    font-style: normal;
    font-weight: 200;
    font-display: swap;
    src: url('./Tajawal/Iurf6YBj_oCad4k1l_6gHrRpiYlJ.woff2') format('woff2');
    unicode-range: U+0600-06FF, U+200C-200E, U+2010-2011, U+204F, U+2E41,
        U+FB50-FDFF, U+FE80-FEFC;
}
/* latin */
@font-face {
    font-family: 'Tajawal';
    font-style: normal;
    font-weight: 200;
    font-display: swap;
    src: url('./Tajawal/Iurf6YBj_oCad4k1l_6gHrFpiQ.woff2') format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
        U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
        U+2215, U+FEFF, U+FFFD;
}
/* arabic */
@font-face {
    font-family: 'Tajawal';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url('./Tajawal/Iurf6YBj_oCad4k1l5qjHrRpiYlJ.woff2') format('woff2');
    unicode-range: U+0600-06FF, U+200C-200E, U+2010-2011, U+204F, U+2E41,
        U+FB50-FDFF, U+FE80-FEFC;
}
/* latin */
@font-face {
    font-family: 'Tajawal';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url('./Tajawal/Iurf6YBj_oCad4k1l5qjHrFpiQ.woff2') format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
        U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
        U+2215, U+FEFF, U+FFFD;
}
/* arabic */
@font-face {
    font-family: 'Tajawal';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('./Tajawal/Iura6YBj_oCad4k1nzSBC45I.woff2') format('woff2');
    unicode-range: U+0600-06FF, U+200C-200E, U+2010-2011, U+204F, U+2E41,
        U+FB50-FDFF, U+FE80-FEFC;
}
/* latin */
@font-face {
    font-family: 'Tajawal';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('./Tajawal/Iura6YBj_oCad4k1nzGBCw.woff2') format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
        U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
        U+2215, U+FEFF, U+FFFD;
}
/* arabic */
@font-face {
    font-family: 'Tajawal';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url('./Tajawal/Iurf6YBj_oCad4k1l4qkHrRpiYlJ.woff2') format('woff2');
    unicode-range: U+0600-06FF, U+200C-200E, U+2010-2011, U+204F, U+2E41,
        U+FB50-FDFF, U+FE80-FEFC;
}
/* latin */
@font-face {
    font-family: 'Tajawal';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url('./Tajawal/Iurf6YBj_oCad4k1l8KiHrFpiQ.woff2') format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
        U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
        U+2215, U+FEFF, U+FFFD;
}
/* arabic */
@font-face {
    font-family: 'Tajawal';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url('./Tajawal/Iurf6YBj_oCad4k1l4qkHrRpiYlJ.woff2') format('woff2');
    unicode-range: U+0600-06FF, U+200C-200E, U+2010-2011, U+204F, U+2E41,
        U+FB50-FDFF, U+FE80-FEFC;
}
/* latin */
@font-face {
    font-family: 'Tajawal';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url('./Tajawal/Iurf6YBj_oCad4k1l4qkHrFpiQ.woff2') format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
        U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
        U+2215, U+FEFF, U+FFFD;
}
/* arabic */
@font-face {
    font-family: 'Tajawal';
    font-style: normal;
    font-weight: 800;
    font-display: swap;
    src: url('./Tajawal/Iurf6YBj_oCad4k1l5anHrRpiYlJ.woff2') format('woff2');
    unicode-range: U+0600-06FF, U+200C-200E, U+2010-2011, U+204F, U+2E41,
        U+FB50-FDFF, U+FE80-FEFC;
}
/* latin */
@font-face {
    font-family: 'Tajawal';
    font-style: normal;
    font-weight: 800;
    font-display: swap;
    src: url('./Tajawal/Iurf6YBj_oCad4k1l5anHrFpiQ.woff2') format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
        U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
        U+2215, U+FEFF, U+FFFD;
}
/* arabic */
@font-face {
    font-family: 'Tajawal';
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src: url('./Tajawal/Iurf6YBj_oCad4k1l7KmHrRpiYlJ.woff2') format('woff2');
    unicode-range: U+0600-06FF, U+200C-200E, U+2010-2011, U+204F, U+2E41,
        U+FB50-FDFF, U+FE80-FEFC;
}
/* latin */
@font-face {
    font-family: 'Tajawal';
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src: url('./Tajawal/Iurf6YBj_oCad4k1l7KmHrFpiQ.woff2') format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
        U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
        U+2215, U+FEFF, U+FFFD;
}
@font-face {
    font-family: 'Milliard Book';
    font-style: normal;
    font-weight: normal;
    src: local('Milliard Book'),
        url('Rene Bieder  Milliard Book.woff') format('woff');
}

@font-face {
    font-family: 'Milliard Book';
    font-style: normal;
    font-weight: normal;
    src: local('Milliard Book'),
        url('Rene Bieder  Milliard Book.woff') format('woff');
}

@font-face {
    font-family: 'Milliard Book Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Milliard Book Italic'),
        url('Rene Bieder  Milliard Book Italic.woff') format('woff');
}

@font-face {
    font-family: 'Milliard Hairline';
    font-style: normal;
    font-weight: normal;
    src: local('Milliard Hairline'),
        url('Rene Bieder  Milliard Hairline.woff') format('woff');
}

@font-face {
    font-family: 'Milliard Hairline Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Milliard Hairline Italic'),
        url('Rene Bieder  Milliard Hairline Italic.woff') format('woff');
}

@font-face {
    font-family: 'Milliard Thin';
    font-style: normal;
    font-weight: normal;
    src: local('Milliard Thin'),
        url('Rene Bieder  Milliard Thin.woff') format('woff');
}

@font-face {
    font-family: 'Milliard Thin Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Milliard Thin Italic'),
        url('Rene Bieder  Milliard Thin Italic.woff') format('woff');
}

@font-face {
    font-family: 'Milliard ExtraLight';
    font-style: normal;
    font-weight: normal;
    src: local('Milliard ExtraLight'),
        url('Rene Bieder  Milliard ExtraLight.woff') format('woff');
}

@font-face {
    font-family: 'Milliard ExtraLight Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Milliard ExtraLight Italic'),
        url('Rene Bieder  Milliard ExtraLight Italic.woff') format('woff');
}

@font-face {
    font-family: 'Milliard Light';
    font-style: normal;
    font-weight: normal;
    src: local('Milliard Light'),
        url('Rene Bieder  Milliard Light.woff') format('woff');
}

@font-face {
    font-family: 'Milliard Light Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Milliard Light Italic'),
        url('Rene Bieder  Milliard Light Italic.woff') format('woff');
}

@font-face {
    font-family: 'Milliard Medium';
    font-style: normal;
    font-weight: normal;
    src: local('Milliard Medium'),
        url('Rene Bieder  Milliard Medium.woff') format('woff');
}

@font-face {
    font-family: 'Milliard Medium Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Milliard Medium Italic'),
        url('Rene Bieder  Milliard Medium Italic.woff') format('woff');
}

@font-face {
    font-family: 'Milliard SemiBold';
    font-style: normal;
    font-weight: normal;
    src: local('Milliard SemiBold'),
        url('Rene Bieder  Milliard SemiBold.woff') format('woff');
}

@font-face {
    font-family: 'Milliard SemiBold Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Milliard SemiBold Italic'),
        url('Rene Bieder  Milliard SemiBold Italic.woff') format('woff');
}

@font-face {
    font-family: 'Milliard Bold';
    font-style: normal;
    font-weight: normal;
    src: local('Milliard Bold'),
        url('Rene Bieder  Milliard Bold.woff') format('woff');
}

@font-face {
    font-family: 'Milliard Bold Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Milliard Bold Italic'),
        url('Rene Bieder  Milliard Bold Italic.woff') format('woff');
}

@font-face {
    font-family: 'Milliard ExtraBold';
    font-style: normal;
    font-weight: normal;
    src: local('Milliard ExtraBold'),
        url('Rene Bieder  Milliard ExtraBold.woff') format('woff');
}

@font-face {
    font-family: 'Milliard ExtraBold Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Milliard ExtraBold Italic'),
        url('Rene Bieder  Milliard ExtraBold Italic.woff') format('woff');
}

@font-face {
    font-family: 'Milliard Heavy';
    font-style: normal;
    font-weight: normal;
    src: local('Milliard Heavy'),
        url('Rene Bieder  Milliard Heavy.woff') format('woff');
}

@font-face {
    font-family: 'Milliard Heavy Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Milliard Heavy Italic'),
        url('Rene Bieder  Milliard Heavy Italic.woff') format('woff');
}

@font-face {
    font-family: 'Milliard Black';
    font-style: normal;
    font-weight: normal;
    src: local('Milliard Black'),
        url('Rene Bieder  Milliard Black.woff') format('woff');
}

@font-face {
    font-family: 'Milliard Black Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Milliard Black Italic'),
        url('Rene Bieder  Milliard Black Italic.woff') format('woff');
}
