.card-post{
    box-shadow:unset;
    margin-left: 0;
    margin-right: 0;
.card-content{
    box-shadow: 0px 4px 21px #7A7A7A40;
padding: 0;
}
.item-footer{
    background: linear-gradient( #ffffffe0,#ffffffe0)
  }
}