.item-input {
    border-radius: 10px;
    margin-top: 15px;
    width: 100%;

    &.disenable {
        --background: #9c9c9c;

        .label-stacked.sc-ion-label-md-h,
        .label-floating.sc-ion-label-md-h {
            margin-top: -8px;
        }
        .label-floating {
            font: normal normal normal 16px/32px Milliard Book, Tajawal;
            letter-spacing: 0px;
            color: #000000;
            opacity: 1;
        }
        .input {
            font: normal normal 600 13px/1px Milliard Book, Tajawal;
            letter-spacing: 0px;
            color: #000000;
        }
    }
    &.error {
        border: 1px solid rgba(255, 0, 0, 0.753);
    }
    .label-stacked.sc-ion-label-md-h,
    .label-floating.sc-ion-label-md-h {
        margin-top: -8px;
    }
    .label-floating {
        font: normal normal normal 16px/32px Milliard Book, Tajawal;
        letter-spacing: 0px;
        color: #9b9b9b;
        opacity: 1;
    }
    .input {
        font: normal normal 600 13px/1px Milliard Book, Tajawal;
        letter-spacing: 0px;
        color: #2d2d2d;
    }
}
.icon {
    position: relative;
    top: 6px;
    font-size: 37px;
}
.toast {
    --border-radius: 2px;
    --box-shadow: 0;
    --start: 30px;
    --end: 30px;
    --height: 35px;

    &.sucsess {
        --color: #ffffff !important;

        --background: #0e6802 !important;
    }
    &.error {
        --color: #eb4381 !important;

        --background: #fdebf1 !important;
    }
    text-align: center;
}
