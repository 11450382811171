.tirker-bar {
    padding-top: 5px;
    direction: rtl;
    padding-bottom: 0px;
    * {
        box-sizing: border-box;
    }
    $duration: 12s;

    @-webkit-keyframes ticker {
        0% {
            -webkit-transform: translate3d(10%, 0, 0);
            transform: translate3d(10%, 0, 0);
            visibility: visible;
        }

        100% {
            -webkit-transform: translate3d(100%, 0, 0);
            transform: translate3d(100%, 0, 0);
        }
    }

    @keyframes ticker {
        0% {
            -webkit-transform: translate3d(10%, 0, 0);
            transform: translate3d(10%, 0, 0);
            visibility: visible;
        }

        100% {
            -webkit-transform: translate3d(100%, 0, 0);
            transform: translate3d(100%, 0, 0);
        }
    }

    .ticker-wrap {
        direction: rtl;
        /*   position: fixed;
  bottom: 0; */
        width: 100%;
        overflow: hidden;
        height: 37px;
        background: #ebebeb;
        padding-right: 100%;
        box-sizing: content-box;
        border-top: 1px solid #d1d1d1;
        border-bottom: 2px solid #d1d1d1;
        .ticker {
            direction: rtl;
            display: inline-block;
            height: 37px;
            line-height: 37px;
            white-space: nowrap;
            padding-left: 100%;
            box-sizing: content-box;

            -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
            -webkit-animation-timing-function: linear;
            animation-timing-function: linear;
            -webkit-animation-name: ticker;
            animation-name: ticker;
            -webkit-animation-duration: $duration;
            animation-duration: $duration;

            &__item {
                display: inline-block;

                padding: 0 2rem;
                font: normal normal 600 14px/18px Milliard Book, Tajawal;
                color: #5e5e5e;
            }
        }
    }
}
