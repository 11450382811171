.button-icon-text {
    width: 100%;
    height: 40px;
    &.active {
        font: normal normal normal 14px/18px Milliard Book, Tajawal;
        letter-spacing: 0px;
        color: #d44c89;
    }
    &.defualt {
        font: normal normal normal 14px/18px Milliard Book, Tajawal;
        letter-spacing: 0px;
        color: #2d2d2f;
    }
    ion-icon {
        padding-right: 5px;
        padding-left: 5px;
    }
}
