.list-item-wishlist {
    border-bottom: 1.2px solid #e0e0e0;
    .title {
        font: normal normal 600 15px/20px Milliard Book, Tajawal;
        letter-spacing: 0px;
        color: #2d2d2f !important;
    }
    .thumbnail {
        width: 80px;
        height: 80px;
        img {
            border: 0.8462259769439697px solid #ffffff;
            border-radius: 10px;
        }
    }
    .category {
        font: normal normal 600 14px/18px Milliard Book, Tajawal;
        letter-spacing: 0px;
        color: #ee417f !important;
        opacity: 1;
        padding-top: 10px;
        padding-bottom: 8px;
    }
    .price {
        font: normal normal normal 14px/16px Milliard Book;
        letter-spacing: 0px;
        color: #2d2d2f !important;
    }
}
