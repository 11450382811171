.item-toggle {
    background: #f9f9f9;
    border-bottom: 1.2px solid #e0e0e0;
    --handle-border-radius: 4px 14px 14px 4px;

    ion-label {
        h2 {
            font: normal normal 600 16px/20px Milliard Book, Tajawal;
            letter-spacing: 0px;
            color: #2d2d2f !important;
        }
        p {
            font: normal normal normal 12px/18px Milliard Book, Tajawal;
            letter-spacing: 0px;
            color: #9b9b9b !important;
            opacity: 1;
        }
    }

    ion-toggle {
        --background: #e9e9e9 0% 0% no-repeat padding-box;
        --background-checked: #34c47c 0% 0% no-repeat padding-box;
        --handle-background-checked: #ffffff 0% 0% no-repeat padding-box;
        --handle-background: transparent
            linear-gradient(0deg, #e9e9e9 0%, #8d8d8e 100%) 0% 0% no-repeat
            padding-box;
    }
}
