.storyModal {
    --background: #55454545;
    --ionicon-stroke-width: 50px;
    padding: 0px;
    --padding-top: 0x;
    --padding-end: 0x;
    --padding-bottom: 0x;
    --padding-start: 0x;
}
.storyModalProduct {
    --background: #969696c2;
    --ionicon-stroke-width: 10px;
    color: white;
    left: 0;
    --padding-start: 5px;
    --padding-end: 5x;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    width: 150px;
}
