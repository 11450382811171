.edit-profile {
    .image-div {
        display: flex;
        justify-content: center;
        width: 100%;
        .loading-upload-image {
            position: absolute;
            top: 50px;
        }
        .border-area {
            border: 1px solid #83838386;
            height: 100px;
            width: 100px;
            padding: 2px;
            border-radius: 50px;
            .image {
                border-radius: 50px;
                height: 94px;
                width: 100px;
                object-fit: cover;
            }
        }
    }
}
