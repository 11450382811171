.body-rate-order {
    .text-title {
        margin-top: 10px;
        font: normal normal 600 14px/20px Milliard SemiBold, Tajawal;
        letter-spacing: 0px;
        color: #5e5e5e;
    }
    .area-review {
        margin-top: 30px;
        background: #ffffff 0% 0% no-repeat padding-box;
        box-shadow: 0px 26px 64px #00000008;
        border-radius: 10px;
        /*     width: 339px; */
        height: 97px;
        padding: 5px 5px 5px 17px;
        .input-area {
            width: 339px;
            height: 97px;
        }
    }
    .rating-star {
        margin-top: 50px;
        display: flex;
        justify-content: center;
    }
}
