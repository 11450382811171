.color-piker {
    border-radius: 5px;
    width: 30px;
    height: 30px;
    .ion-icon {
        color: #ffffff !important;
        position: absolute;
        left: -3px;
    }
    .ion-icon-ar {
        color: #ffffff !important;
        position: absolute;
        left: 8px;
    }
}
