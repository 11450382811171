.cart-bag {
    .price-footer {
        text-align: center;
        /*      width: 20%; */
        margin-left: 30px;
        margin-right: 30px;
        font-weight: 500;
        font-size: 25px;
        background: -webkit-linear-gradient(113deg, #ee417f 0%, #6680c6 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        /*       color: transparent linear-gradient(113deg, #EE417F 0%, #6680C6 100%) 0% 0% !important; */
    }
}
