#Sliderer {
    .alginCnter {
    }
    /* Decorations */
    /*       .col-6 { color: #fff; font-size: 48px; padding-bottom: 20px; padding-top: 18px; }
        .col-6:nth-child(3n+1) { background: #c69; }
        .col-6:nth-child(3n+2) { background: #9c6; }
        .col-6:nth-child(3n+3) { background: #69c; } */
    .scrolling-wrapper {
        text-align: -webkit-center;
        overflow-x: scroll;
        overflow-y: hidden;
        white-space: nowrap;
        -webkit-overflow-scrolling: touch;

        .card {
            background: rgba(255, 255, 255, 0);
            display: inline-block;
            padding: 5px;
            border: unset;

            .card-item {
                text-align: initial;
                color: unset;
                width: 100%;
                height: 100%;
                text-decoration: none;
                box-shadow: none;
                margin: 0;
                margin-top: 10px;
                background: #ff000000;
                .discount-badge {
                    position: absolute;
                    top: 336px;
                    right: 0;
                    text-align: center;
                    background: #ee417f;
                    border-radius: 10px 0px;
                    color: #f6f6f6;
                    width: 75px;
                    height: 25px;
                    font: normal normal 600 13px/20px Milliard Book, Tajawal;
                }
                .discount-badge-ar {
                    position: absolute;
                    top: 336px;
                    left: 0;
                    text-align: center;
                    background: #ee417f;
                    border-radius: 0px 10px;
                    color: #f6f6f6;
                    width: 75px;
                    height: 25px;
                    font: normal normal 600 13px/20px Milliard Book, Tajawal;
                }
                .img-grid {
                    width: 100%;
                    max-width: 315px;
                    height: 361px;

                    border-radius: 10px;
                    object-fit: cover;
                }
                .item {
                    --padding-start: 0;
                    ion-label {
                        margin-top: 8px;
                        .title {
                            color: #2d2d2f;
                            font: normal normal 600 16px/20px Milliard Book,
                                Tajawal, Tajawal;
                        }
                        .category {
                            font: normal normal normal 14px/18px Milliard Book,
                                Tajawal;
                            letter-spacing: 0px;
                            color: #2967ff;
                        }
                        .category-lg {
                            font: normal normal normal 14px/18px Milliard Book,
                                Tajawal;
                            letter-spacing: 0px;
                            color: #2967ff;
                            float: right;
                        }
                        .price-base {
                            margin-top: 5px;
                            .base {
                                font: normal normal normal 16px/20px Milliard
                                    Book;
                                letter-spacing: 0px;
                                color: #2d2d2f;
                            }
                            .discount {
                                padding-left: 10px;
                                padding-right: 10px;
                                font: normal normal normal 14px/18px Milliard
                                    Book;
                                letter-spacing: 0px;
                                color: #8d8d8e;
                            }
                        }
                    }
                }
            }
        }
    }
}
