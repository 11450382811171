.content-footer {
    margin-top: 10px;
    text-align: center;
    .title {
        font: normal normal normal 15px/32px Milliard Book, Tajawal;
    }
    .card-align {
        text-align: center;
        width: 100%;

        display: flex;
        justify-content: center;
    }
}
.facebook-icon{
    
    ion-icon{
        width: 39px;
        height: 39px;
    }
}
.card-icon{
    background: #f1f1f1 0% 0% no-repeat padding-box;
border-radius: 10px;
opacity: 1;
width: 92px;
height: 64px;
display: flex;
align-items: center;
justify-content: center;

box-shadow: unset !important;
text-align: center;
ion-icon{
    vertical-align: middle;
}
/* padding-inline-start: var(--padding-start);
padding-inline-end: var(--padding-end); */

}