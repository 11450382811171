.header-modal {
    background: #f9f9f9 0% 0% no-repeat padding-box;
    padding-top: 20px;
    padding-bottom: 2px;
    &.header-md::after {
        background-image: unset;
    }

    ion-toolbar:last-of-type {
        --border-width: 0;
    }

    .title {
        font: normal normal bold 21px/32px Milliard Book, Tajawal;
        letter-spacing: 0px;
        color: #2d2d2d;
    }
}
