.item-order {
    border-bottom: 1.4px solid #e0e0e0;
    margin-top: 10px;

    .thumbnail {
        width: 80px;
        height: 80px;
        .col-padding {
            padding: 0;
        }
        .one-image {
            border-radius: 10px;
            width: 100%;
            height: 100%;
            border: 0.8462259769439697px solid #ffffff;
        }

        .one-helf {
            border-radius: 0px 0px 10px 10px;
            border: 0.8462259769439697px solid #ffffff;
            width: 100%;
            height: 40px;
        }
        .two-image {
            border: 0.8462259769439697px solid #ffffff;
            width: 40px;
            height: 40px;
        }
        .three-image {
            border: 0.8462259769439697px solid #ffffff;
            width: 100%;
            height: 40px;
        }
    }
    .title {
        font: normal normal 600 16px/20px Milliard Book, Tajawal;
        letter-spacing: 0px;
        color: #2d2d2f;
        opacity: 1;
    }
    .price {
        padding-top: 5px !important;
        font: normal normal 500 16px/20px Milliard SemiBold, Tajawal;
        letter-spacing: 0px;
        color: #2d2d2f !important;
        opacity: 1;
    }
    .state-opetion {
        padding-top: 10px;
    }
    .state-order {
        font: normal normal 600 14px/18px Milliard Light, Tajawal;
        letter-spacing: 0px;
        text-transform: capitalize;
    }
    .link-view {
        text-decoration: underline;
        font: normal normal 500 14px/24px Milliard SemiBold, Tajawal;
        letter-spacing: 0px;
        color: #2967ff;
    }
}
