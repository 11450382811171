.scrolling-wrapperr {
    text-align: -webkit-center;
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
    text-align: initial;
    margin-bottom: 20px;
    -webkit-overflow-scrolling: touch;
    .card {
        border-radius: 12px;
        width: 120px;
        height: 168px;
        filter: brightness(95%);

        margin-inline: 5px;
        display: inline-block;
        background-size: cover;
        .text {
            font-family: Milliard Book, Tajawal;
            color: white;
            font-size: 14px;
            font-weight: 600;
            position: relative;
            top: 145px;
            right: 7px;
            left: 7px;
        }
    }
}
