.input-floating-item {
    background: white;
    padding-top: 25px;
    border-radius: 10px;
    padding-right: 2px;
    padding-left: 2px;
    width: 100%;
    /****  floating-Lable style start ****/
    .floating-label {
        position: relative;
        margin-bottom: 5px;
    }
    .floating-input,
    .floating-select {
        font: normal normal 600 13px/1px Milliard Book, Tajawal;
        letter-spacing: 0px;
        color: #2d2d2d;
        padding: 5px 8px;
        display: block;
        width: 100%;
        height: 30px;
        background-color: transparent;
        border: none;
        /*     border-bottom:1px solid #757575; */
    }

    .floating-input:focus,
    .floating-select:focus {
        outline: none;
        border-bottom: 2px solid #ffffff;
    }

    label {
        color: #9b9b9b;
        font: normal normal normal 16px/15px Milliard Book, Tajawal;

        position: absolute;
        pointer-events: none;
        left: 5px;
        top: -5px;
        transition: 0.2s ease all;
        -moz-transition: 0.2s ease all;
        -webkit-transition: 0.2s ease all;
    }

    .floating-input:focus ~ label,
    .floating-input:not(:placeholder-shown) ~ label {
        top: -18px;
        font: normal normal normal 13px/15px Milliard Book, Tajawal;
        color: #6d7dc3;
    }

    .floating-select:focus ~ label,
    .floating-select:not([value='']):valid ~ label {
        top: -18px;
        font-size: 14px;
        color: #d6d6d6;
    }

    /* active state */
    .floating-input:focus ~ .bar:before,
    .floating-input:focus ~ .bar:after,
    .floating-select:focus ~ .bar:before,
    .floating-select:focus ~ .bar:after {
        width: 50%;
    }

    *,
    *:before,
    *:after {
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
    }

    .floating-textarea {
        min-height: 30px;
        max-height: 260px;
        overflow: hidden;
        overflow-x: hidden;
    }

    /* highlighter */
    .highlight {
        position: absolute;
        height: 50%;
        width: 100%;
        top: 15%;
        left: 0;
        pointer-events: none;
        opacity: 0.5;
    }

    /* active state */
    .floating-input:focus ~ .highlight,
    .floating-select:focus ~ .highlight {
        -webkit-animation: inputHighlighter 0.3s ease;
        -moz-animation: inputHighlighter 0.3s ease;
        animation: inputHighlighter 0.3s ease;
    }

    /* animation */
    @-webkit-keyframes inputHighlighter {
        from {
            background: #ffffff00;
        }
        to {
            width: 0;
            background: transparent;
        }
    }
    @-moz-keyframes inputHighlighter {
        from {
            background: #dfdfdf00;
        }
        to {
            width: 0;
            background: transparent;
        }
    }
    @keyframes inputHighlighter {
        from {
            background: #ffffff04;
        }
        to {
            width: 0;
            background: transparent;
        }
    }
}
