.item-header {
    margin-bottom: 8px;

    .thumbnail {
        width: 42px;
        height: 42px;
        .img-thumbnail {
            border-radius: 10px;
        }
    }
    .name {
        font: normal normal 600 14px/20px Milliard Book, Tajawal;
        letter-spacing: 0px;
        color: #2d2d2f;
    }
    .time {
        font: normal normal normal 12px/16px Milliard Book;
        letter-spacing: 0px;
        color: #9b9b9b;
    }
}
