.footer{
    background: #FFFFFF 0% 0% no-repeat padding-box;
box-shadow: 0px -5px 18px #00000008;

padding-left: 22px;
padding-right: 22px;
ion-toolbar{
    margin-top: 5px;
    margin-bottom: 5px;
}
ion-toolbar:first-of-type {
    --border-width: 0;

}}