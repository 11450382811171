.header-product {
    position: fixed;
    .title {
        font: normal normal bold 21px/32px Milliard Book, Tajawal;
        letter-spacing: 0px;
        color: #ffffff;
    }
    .icon-title {
        background: #6f6f6f5d;
        border-radius: 10%;
        position: relative;
        top: 3px;
        font-size: 20px;
        color: #ffffff;
        padding:2px 10px;
    }
}
