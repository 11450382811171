.progress-orders-vartecl {
    .progess {
        height: 385px;
        width: 40px;
        background: rgb(233, 233, 233);
        border-radius: 10px;
        .step {
            &.-start {
                /*       width: 40px;
    height: 99px; */
                border-radius: 10px;
            }
            &.-contenu {
                width: 40px;
                height: 99px;
            }
        }
    }
    .rate-order {
        background: #4cd1001a 0% 0% no-repeat padding-box;
        border: 0.8462259769439697px solid #4cd100;
        border-radius: 10px;
        font: normal normal 600 11px/18px Milliard Book, Tajawal;
        letter-spacing: 0px;
        color: #4cd100;
        text-transform: capitalize;
        /*         width: 96px; */
        height: 28px;
        text-align: center;
    }
}
