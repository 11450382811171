.my-orders{

    .list{
        margin-top: 20px;
        background: #ffffff00;
    }
    .header-segemnt{
     height: 30px;
/*         margin-top: 10px; */
background: #F9F9F9 0% 0% no-repeat padding-box;
        &.header-md::after{
         background-image:unset !important; 

        }
        ion-toolbar:last-of-type{

         --border-width:0
        }
     }
    
}