.order-placed {
    position: absolute;
    top: 40%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    text-align: center;
    .img {
        width: 250px;
    }
    .title {
        padding-top: 15px;
        .text {
            font: normal normal 600 16px/20px Milliard Book, Tajawal;
            letter-spacing: 0px;
            color: #2d2d2f;
        }
    }
    .subtitle {
        padding-top: 15px;
        padding-left: 20px;
        padding-right: 20px;
        .text {
            padding-top: 16px;
            font: normal normal bold 14px/18px Milliard Book, Tajawal;
            letter-spacing: 0px;
            color: #2d2d2f;
        }
    }
}
