.list-item {
    border-bottom: 1.2px solid #e0e0e0;
    .title {
        font: normal normal 600 16px/20px Milliard Book, Tajawal;
        letter-spacing: 0px;
        color: #2d2d2f !important;
    }
    .description {
        font: normal normal normal 12px/16px Milliard Book, Tajawal;
        letter-spacing: 0px;
        color: #9b9b9b !important;
    }
}
