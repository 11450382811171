.item-progeress {
    ion-label {
        margin-top: 0;
        .title {
            font: normal normal 600 16px/20px Milliard Book, Tajawal;
            letter-spacing: 0px;
            color: #2d2d2f !important;
        }
        .descrptions {
            margin-top: 10px;
            font: normal normal normal 11px/18px Milliard Book, Tajawal;
            letter-spacing: 0px;
            /* white-space: initial; */
            color: #c8c8c8 !important;
        }
    }
}
