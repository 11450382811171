.btn-lable {
    width: 100%;
    text-align: right;
    p {
        font: normal normal 600 13px/32px Milliard Book, Tajawal;
        letter-spacing: 0px;
        color: #2d2d2d;
        ion-icon {
            position: relative;
            top: 1px;
        }
    }
}
