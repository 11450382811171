.header-started{
    
    display: flex;
    padding-top: var(--ion-safe-area-top, 0);
 .button-back{
    width: 42px;
  
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border-radius: 4px;
color: #6680C6;


    

 }

    .img-center{
        right: 10px;
     text-align: center;
     width: 100%;
     position: relative;
     img{
        height: 45px;

         margin-top: 0px;
     }
 }

}