
.footer-input{
    background: #F9F9F9 0% 0% no-repeat padding-box;
border-radius: 10px;
margin-top: 5px;
margin-bottom: 10px;
/* padding: 4px; */
.input{
    background:  #F9F9F9 0% 0% no-repeat padding-box;
    border: unset;
    width: 100%;
    height: 37px;
    border-radius: 10px;
  padding-left: 10px;
/*   padding-right: 50px; */
}
.icon-color{
    color: #9B9B9B !important;
}

}