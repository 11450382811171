.item-cart-product {
    border-bottom: 1px solid #e0e0e0d8;

    .thumbnail {
        border-radius: 10px;
        width: 80px;
        height: 95px;
        border: 0.8462259769439697px solid #ffffff;
        img {
            border-radius: 10px;
        }
    }
    ion-label {
        .name-product {
            font: normal normal 600 16px/20px Milliard Book, Tajawal;
            letter-spacing: 0px;
            color: #2d2d2f !important;
        }
        .options {
            float: right;
        }
        .options-ar {
            float: left;
        }
        .category {
            position: relative;
            top: 2px;
            font: normal normal 600 14px/18px Milliard Book, Tajawal;
            letter-spacing: 0px;
            color: #ee417f !important;
        }
        .padding-top-15 {
            padding-top: 5px;
        }
        .qunitiy {
            float: right;
            display: flex;

            .qunitiy-number {
                padding-left: 15px;
                padding-right: 15px;
                color: #2e3e5c !important;
                font-size: 16px;
                font-weight: 600;
                position: relative;
                top: 2px;
            }
            .circal {
                width: 24px;
                height: 24px;
                border-radius: 50%;
                background: #efefef 0% 0% no-repeat padding-box;
                .button {
                    position: relative;
                    right: 11px;
                    bottom: 5px;
                }
                .button-ar {
                    position: relative;
                    right: -11px;
                    bottom: 5px;
                }
            }
        }
        .price {
            font: normal normal 600 16px/20px Milliard Book, Tajawal;
            letter-spacing: 0px;
            color: #2d2d2f;
        }
    }
}
