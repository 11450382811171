.header-section {
    background: linear-gradient(135deg, #ee417f 0%, #6680c6 100%) 0% 0% no-repeat padding-box padding-box transparent;
    padding-top: 4px;
    padding-bottom: 4px;
    .text-title {
        color: #f9f9f9;
        font: normal normal bold 18px/22px Milliard Book, Tajawal;
    }
    .link-all {
        text-align: right;
        text-decoration: none;
        font: normal normal 600 15px/24px Milliard Book, Tajawal;
        letter-spacing: 0px;
        color: #f9f9f9;
    }
}
