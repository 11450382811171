.product {
    .item-footer {
        background: linear-gradient(#ffffffe0, #ffffffe0);
    }
    .badge-attributes {
        position: relative;
        top: 6px;
        font-size: 17px;
    }
    .badge-value {
        position: relative;
        margin-left: 4px;
        top: 5px;
    }
    .product-details {
        font: normal normal normal 15px/16px Milliard Book, Tajawal;
        letter-spacing: 0px;
        color: #9b9b9b;
    }
    .title {
        font: normal normal 600 16px/20px Milliard Book, Tajawal;
        letter-spacing: 0px;
        color: #2d2d2f;
        margin-bottom: 1px;
        margin-right: 2px;
        margin-left: 4px;
    }
}
