.header-conent {
    margin-top: 50px;
    margin-bottom: 70px;
    .header-lable {
        h1 {
            font: normal normal bold 21px/32px Milliard Bold, Tajawal;
            color: #2d2d2d;
        }
        p {
            font: normal normal normal 14px/16px Milliard Book, Tajawal;
            letter-spacing: 0px;
            color: #9b9b9b;
            padding-top: 4px;
        }
    }
}
